<template>
  <el-container>
    <el-header height="76px">
      <div class="header">
        <img src="https://cdn.blackgod.cn/website/blackGodIcon.png" style=" width: 80px;height: 80px;">
        <div class="title">
          <div style="color: #ffffff;font-size: 18px">黑神赏</div>
          <div style="font-size: 14px;color: #a5a5a5;margin-top: 8px;">在线手办、模玩售卖抽赏平台</div>
        </div>
        <img src="https://cdn.blackgod.cn/website/yxxyTitle.png" style=" width: 175px;height: 17px;margin-left: 441px;">
      </div>
    </el-header>
    <div style="position: relative;display: flex;">
      <img src="https://cdn.blackgod.cn/website/websiteMainV3.jpg" style=" width: 100%;height:  100%;">
      <img :src="srcImg" class="androidQr">
      <div class="wx" @click="change(1)"></div>
      <div class="app" @click="change(2)"></div>
    </div>
    <div class="footer">
      <div style="display: flex; align-items: center;justify-content: center;">
        <div class="footerLeft">
          <img src="https://cdn.blackgod.cn/website/blackGodIcon.png" style=" width: 120px;height: 120px;">
          <div style="width: 576px;font-size: 18px;color: #ffffff;text-align: left;margin-left: 16px;margin-top: 21px;">
            杭州悦享心愉网络科技有限公司旗下产品《黑神赏》是一款在线销售盲盒类商品的app，涉及手办、模玩、潮玩、谷圈等多种商品，页面清晰，交互简单，具有良好的辨识度，平台严格遵守国家盲盒类法律法规，致力于完善及新增更多对好玩玩法，为用户带来更好更优质对体验。
          </div>
          <div style="display: flex;align-items: center;margin-top: 30px;margin-left: 9px;">
            <img src="https://cdn.blackgod.cn/website/18pic.png" style=" width: 58px;height: 58px;">
            <div style="font-size: 18px;color: #ffffff;margin-left: 10px;">本平台适合18+岁的用户使用，未成年人严禁使用</div>
          </div>
        </div>
        <div class="footerRight">
          <div style="font-size: 30px;color: #ffffff;">联系我们</div>
          <div style="display: flex;align-items: center;margin-top: 30px;margin-left: 8px;">
            <img src="https://cdn.blackgod.cn/website/emailIcon.png" style=" width: 24px;height: 24px;">
            <div style="font-size: 18px;color: #ffffff;margin-left: 10px;">邮箱：blackgodsh@163.com</div>
          </div>
          <div style="display: flex;align-items: center;margin-top: 30px;margin-left: 8px;">
            <img src="https://cdn.blackgod.cn/website/contactIcon.png" style=" width: 24px;height: 24px;">
            <div style="font-size: 18px;color: #ffffff;margin-left: 10px;">联系方式：18072795236</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div style="color: #ffffff;font-size: 16px;margin-top: 32px;margin-bottom: 78px;">
        Hangzhou Yuexixin Network Technology Co., LTD. All rights reserved ｜ <a style="color:#fff" href="https://beian.miit.gov.cn/">浙ICP备2024109395号-1</a>
      </div>

    </div>

  </el-container>
</template>

<script>
export default {
  data() {
    return {
      srcImg: "https://cdn.blackgod.cn/website/aliQr.jpg"
    }
  },
  methods: {
    change(type) {
      if (type == 2) {
        this.srcImg = "https://cdn.blackgod.cn/website/appDownLoadQr.png"
      } else if (type == 1) {
        this.srcImg = "https://cdn.blackgod.cn/website/aliQr.jpg"
      }
    }
  }
};
</script>
<style scoped>
.el-header {
  background-color: #1A171E;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: baseline;
}

.androidQr {
  width: 10%;
  height: 8.5%;
  position: absolute;
  right: 38%;
  top: 23.5%;
}

.wx {
  width: 18%;
  height: 4%;
  position: absolute;
  right: 18%;
  top: 23%;
}

.app {
  width: 18%;
  height: 4%;
  position: absolute;
  right: 18%;
  top: 28.5%;
}

.footer {
  width: 100%;
  background: #031812;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.footerLeft {
  display: flex;
  flex-direction: column;
  align-items: start;

}

.footerRight {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 46px;
  margin-left: 233px
}

.line {
  width: 70%;
  height: 1px;
  background: #FFFFFF;
  margin: 0 auto;
  margin-top: 36px;

}
</style>
